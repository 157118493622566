body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    margin: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html {
    font-size: 62.5%; /* 62.5% of 16px = 10px */

    @media (min-width: 35rem) {
        /* 20*16px = 320px */
        // background-color: lemonchiffon;
        font-size: 81.25%;
        /* 200% of 16px = 32px */
    }

    @media (min-width: 60em) {
        /* 30*16px = 480px */
        // background-color: lightblue;
        font-size: 100%; /* 300% of 16px = 48px */
    }

    @media (min-width: 90em) {
        /* 30*16px = 480px */
        // background-color: lightblue;
        font-size: 120%; /* 300% of 16px = 48px */
    }
}
