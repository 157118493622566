.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 20px;
}

.login-items-wrapper {
    margin: 15px 0;
}
.text-field-width{
    width: 16rem;
}
