
.button-center {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
}
.mdc-text-field{
    width: 100%;
}
.padding-is-0{
    padding: 0px;
    height: 24px;
    width: 24px;
}