.dashboard-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;;
}

.header-padding {
    display: flex;
    flex-direction: row;
    padding: 15px 0 25px;
    width: 100%;
    gap: 20px;
}
.tab-wrapper{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.table-width{
    width: 85vw;
}